import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom';

type Props = {
    children: React.ReactElement;
    trackingId: string;
}

function Tracer({ children, trackingId }: Props) {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialized, setInitialized] = useState(false);

    const appTraceString = searchParams.get('s');

    useEffect(() => {
        // 仅正式环境触发统计
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize(trackingId);
            setInitialized(true);
        }

        if (appTraceString) {
            if (localStorage.getItem('_trace_token') !== appTraceString) {
                console.log('update trace token');

                localStorage.setItem('_trace_token', appTraceString)
            }
        } else {
            // url 没有 trace token 但是本地存了则体现出来，方便网页复制链接
            const localAppTraceToken = localStorage.getItem('_trace_token')
            if (localAppTraceToken) {
                setSearchParams({
                    s: localAppTraceToken
                })
            }
        }
    }, [])

    useEffect(() => {
        if (initialized) {
            ReactGA.send({ hitType: "pageview", page: `${location.pathname}${location.search}` })
        }

        if (!searchParams.get('s')) {
            // url 没有 trace token 但是本地存了则体现出来，方便网页复制链接
            const localAppTraceToken = localStorage.getItem('_trace_token')
            if (localAppTraceToken) {
                setSearchParams({
                    s: localAppTraceToken
                })
            }
        }
    }, [initialized, location]);

    return <>{children}</>;
}

export default Tracer