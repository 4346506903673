import { faKey, faUser, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { getProfile, register as registerApi } from "../services";
import { useForm } from "react-hook-form"
// @ts-ignore
import Modal from 'react-modal'

Modal.setAppElement('#root');

function Register() {
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm()
    const [searchParams, _] = useSearchParams();
    const [modalState, setModalState] = useState(false)
    const [submitState, setSubmitState] = useState(0)

    let defaultInviteCode = searchParams.get('invite_code');

    if (!defaultInviteCode) {
        defaultInviteCode = localStorage.getItem('_trace_token');
    }

    let stateDesc;

    switch (submitState) {
        case 1:
            stateDesc = <div><FontAwesomeIcon icon={faSpinner} spin /> 注册中，请稍后</div>
            break;
        case 2:
            stateDesc = <div>注册失败，请稍后重试</div>
            break;
        default:
            stateDesc = <div></div>
    }

    const registerSubmit = async ({ username, password, invite_code }: any) => {
        setSubmitState(1)
        setModalState(true)

        try {
            const res = await registerApi(username, password, invite_code)

            if (res.data.token) {
                localStorage.setItem('_auth_token', res.data.token);

                // 注册成功则清空原来的 trace token
                localStorage.removeItem('_trace_token')

                // 替换新的 trace token
                const profile = await getProfile()
                localStorage.setItem('_trace_token', profile.data.invite_code)

                navigate("/profile", { replace: true })
            }
        } catch (error) {
            setSubmitState(2)
            setTimeout(() => setModalState(false), 2000)
            console.error(error)
        }
    }

    useEffect(() => {
        document.title = '注册 - 污吧';
    }, [])

    return <div>
        <form className="form" onSubmit={handleSubmit(registerSubmit)}>
            <div className='form-item'>
                <label htmlFor="username">用户名</label>
                <div className="form-input">
                    <i><FontAwesomeIcon icon={faUser} /></i>
                    <input
                        id="username"
                        placeholder="用户名"
                        type='text'
                        autoComplete="username"
                        {...register("username", { required: true })}
                    />
                </div>
            </div>
            <div className='form-item'>
                <label htmlFor="password">密码</label>
                <div className="form-input">
                    <i><FontAwesomeIcon icon={faKey} /></i>
                    <input
                        id="password"
                        placeholder="密码"
                        type='password'
                        autoComplete="current-password"
                        {...register("password", { required: true })}
                    />
                </div>
            </div>
            <div className='form-item'>
                <label htmlFor="invite_code">邀请码</label>
                <div className="form-input">
                    <i><FontAwesomeIcon icon={faKey} /></i>
                    <input
                        id="invite_code"
                        placeholder="请输入邀请码，没有可留空"
                        type='text'
                        {...register("invite_code", { value: defaultInviteCode })}
                    />
                </div>
                <div className="desc">* 有邀请码注册可获得观影券</div>
            </div>
            <div className="form-submit">
                <button type="submit" className="button primary">注册</button>
                <span className="text-gray-200 flex-none text-center mx-2 leading-10">- or -</span>
                <Link className="button simple" to="/login">已有账号，去登录</Link>
            </div>
        </form>
        <Modal
            className="modal"
            isOpen={modalState}
        >
            {stateDesc}
        </Modal>
    </div>
}

export default Register