import './styles/profile-card.css'

export type ProfileCardProps = {
    nickname: string;
    invite_code: string;
    score: {
        ticket: number;
        invite: number;
    }
}

function ProfileCard(props: ProfileCardProps) {
    return <div className='profile'>
        <div className='profile-base'>
            <div id='profile-nickname'>{props.nickname}</div>
            <div id='profile-invite-code'>邀请码 | {props.invite_code}</div>
        </div>
        <div id='profile-score'>
            <div className='item'>
                <div className='name'>邀请数</div>
                <div className='val'>{props.score.invite}</div>
            </div>
            <div className='item'>
                <div className='name'>影券</div>
                <div className='val'>{props.score.ticket}</div>
            </div>
        </div>
    </div>
}

export default ProfileCard