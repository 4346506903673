import axios from "axios";

// 注入 auth token
axios.interceptors.request.use(config => {
    const token = localStorage.getItem('_auth_token')

    if (token) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`
        }
    }

    return config
})

axios.interceptors.response.use(response => response, error => {
    if (error.response) {
        // 判断是否为授权问题
        if (error.response.status == 401) {
            console.log('Unauthorized 401, redirect to login.')
            // 跳转登录页
            window.location.href = '/login'
        }
    }

    return Promise.reject(error)
})

export default axios