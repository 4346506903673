import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import profileReducer from './profile/profileSlice'
import postListReducer from './postList/postListSlice'
import categoryReducer from './category/categorySlice'

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    postList: postListReducer,
    category: categoryReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
