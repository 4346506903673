import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getCategoryList } from '../../services'

export const getCategoryListAsync = createAsyncThunk(
    'postList/getCategoryList',
    async () => {
        const { data } = await getCategoryList()
        return data
    }
)

export type Category = { id: number; title: string }
export type CategoryList = {
    current: number;
    list: Category[];
}

const initialState: CategoryList = {
    current: 0,
    list: []
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCurrentTab: (state, action: PayloadAction<number>) => {
            state.current = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategoryListAsync.fulfilled, (state, action) => {
                return {
                    current: action.payload[0].id,
                    list: [...action.payload]
                }
            })
    }
})

export const { setCurrentTab } = categorySlice.actions

export default categorySlice.reducer