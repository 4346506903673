import { Outlet } from 'react-router-dom'
import './layout.css'
import logo from '../logo-dark.png'

function LoginLayout() {
    return <div>
        <div className="login-page">
            <div className='login-page-logo'>
                <img src={logo} />
            </div>
            <div className='login-container'>
                <Outlet />
            </div>
        </div>
        <div className='login-page-bg'></div>
    </div>
}

export default LoginLayout