import React from "react"
import './styles/loading.css';

export type LoadingProps = {
    loading: boolean;
    children: React.ReactElement
}

function Loading(props: LoadingProps) {
    let show;

    if (props.loading) {
        show = <div className="loading">加载中，请稍后</div>
    } else {
        show = props.children
    }

    return show
}

export default Loading