import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { updateProfileAsync } from "../app/profile/profileSlice"
import ProfileCard from "../components/ProfileCard"
import './styles/profile.css'

function Profile() {
    const profile = useAppSelector(state => state.profile)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const updateProfile = async () => {
        await dispatch(updateProfileAsync())
    }

    useEffect(() => {
        document.title = '个人中心 - 污吧'
        updateProfile().catch(console.error)
    }, [])

    const logout = () => {
        localStorage.removeItem('_auth_token')

        navigate('/login')
    }

    return <div>
        <div className="p-4">
            <ProfileCard {...profile} />
            <div className="mt-4">
                <div className="profile-control-panel">
                    <Link className="item" to="/profile/invite">我的邀请</Link>
                    <div className="item" onClick={logout}>退出登录</div>
                </div>
            </div>
        </div>
    </div>
}

export default Profile