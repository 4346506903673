import classNames from 'classnames';
import './styles/tabs.css'

export type TabProps = {
    active: boolean;
    id: number;
    title: string;
    onChange?: (key: number) => void
}

export type TabsProps = {
    tabs: { id: number; title: string }[];
    current?: number;
    onChange?: (key: number) => void
};

function Tab(props: TabProps) {
    return <div
        className={classNames('tab', { active: props.active })}
        onClick={() => {
            if (props.onChange) {
                props.onChange(props.id)
            }
        }}>
        {props.title}
    </div>
}

function Tabs(props: TabsProps) {
    return <div className={'w-full'}>
        <div className={'tabs-container'}>
            {props.tabs.map((item, index) => (
                <Tab onChange={props.onChange} id={item.id} title={item.title} key={item.id} active={props.current ? props.current == item.id : index == 0} />
            ))}
        </div>
    </div>
}

export default Tabs