import { useNavigate } from 'react-router-dom';
import './styles/content-list.css';

export type ContentItemType = {
    id: string | number;
    title: string;
    thumbnail: string;
}

export type ContentItemProps = {
    id: string | number;
    title: string;
    thumbnail: string;
    onClick?: (key: string | number) => void
}

export type ContentListProps = {
    list: ContentItemType[];
    onClickPost?: (key: number | string) => void
}

export function ContentItem(props: ContentItemProps) {
    return <div className="item" onClick={() => {
        if (props.onClick) {
            props.onClick(props.id)
        }
    }}>
        <div className="thumbnail">
            <img src={props.thumbnail} />
        </div>
        <div className="title">{props.title}</div>
    </div>
}

function ContentList(props: ContentListProps) {
    return <div className="content-list">
        {props.list.map(item =>
            <ContentItem
                onClick={key => {
                    if (props.onClickPost) {
                        props.onClickPost(key)
                    }
                }}
                key={item.id}
                id={item.id}
                title={item.title}
                thumbnail={item.thumbnail}
            />)}
    </div>
}

export default ContentList