import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AppLayout from './layout/AppLayout';
import Profile from './pages/Profile';
import LoginLayout from './layout/LoginLayout';
import Login from './pages/Login';
import Register from './pages/Register';
import UserInvite from './pages/profile/UserInvite';
import Post from './pages/Post';
import Tracer from './components/Tracer';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Tracer trackingId='G-GPJX0M34FD'>
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route path="/profile/invite" element={<UserInvite />} />
            <Route path="/post/:postId" element={<Post />} />
            <Route path="/login" element={<LoginLayout />}>
              <Route path="" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Route>
          </Routes>
        </Tracer>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
