import { useEffect, useState } from "react"
import ContentList from "../components/ContentList"
import Tabs from "../components/Tabs"
import { getCategoryList, getCategoryPostList } from "../services"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { getCategoryListAsync, setCurrentTab } from '../app/category/categorySlice'
import { getCategoryPostListAsync, appendCategoryPostListAsync } from '../app/postList/postListSlice'
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";

function Home() {
    const { category, postList } = useAppSelector(state => ({ category: state.category, postList: state.postList }))
    const [refresh, setRefresh] = useState(false)
    const dispatch = useAppDispatch()

    const dispatchGetCategoryList = async () => {
        setRefresh(true)
        await dispatch(getCategoryListAsync())
    }

    const dispatchSetCurrentCategory = async (id: number) => {
        setRefresh(true)
        await dispatch(setCurrentTab(id))
    }

    const dispatchGetCategoryPostList = async (id: number) => {
        await dispatch(getCategoryPostListAsync(id))
    }

    const dispatchAppendCatehgoryPostList = async (id: number) => {
        await dispatch(appendCategoryPostListAsync({categoryId: id, page: postList.page + 1}))
    }

    useEffect(() => {
        console.log('change current category: ', category.current)

        if (refresh) {
            dispatchGetCategoryPostList(category.current)
            setRefresh(false)
        }
    }, [category.current])

    useEffect(() => {
        document.title = '首页 - 污吧';
        
        if (category.list.length == 0) {
            console.log('trigger dispatch category list getter.')
            dispatchGetCategoryList()
        }
    }, [])

    const navigate = useNavigate()

    return <div className="px-2">
        <Tabs tabs={category.list} current={category.current} onChange={key => dispatchSetCurrentCategory(key)} />
        <div className="h-1"></div>
        <InfiniteScroll
            dataLength={postList.list.length}
            hasMore={postList.has_more}
            next={() => dispatchAppendCatehgoryPostList(category.current)}
            loader={<p>加载中</p>}
        >
            <Loading loading={postList.loading}>
                <ContentList list={postList.list} onClickPost={key => navigate(`/post/${key}`)} />
            </Loading>
        </InfiniteScroll>
    </div>
}

export default Home