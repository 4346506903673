import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getCategoryPostList } from '../../services'

export const appendCategoryPostListAsync = createAsyncThunk(
    'postList/appendCategoryPostList',
    async ({ categoryId, page }: { categoryId: number | string, page: number }) => {
        const { data } = await getCategoryPostList(categoryId, page)
        return { page, list: data.data, has_more: data.has_more }
    }
)

export const getCategoryPostListAsync = createAsyncThunk(
    'postList/getCategoryPostList',
    async (categoryId: number | string) => {
        const { data } = await getCategoryPostList(categoryId)
        return { page: 1, list: data.data, has_more: data.has_more }
    }
)

export type Post = {
    id: string | number;
    title: string;
    thumbnail: string;
}

export type PostList = {
    page: number;
    list: Post[];
    loading: boolean;
    has_more: boolean;
}

const initialState: PostList = {
    page: 1,
    list: [],
    loading: true,
    has_more: false,
}

const postListSlice = createSlice({
    name: 'postList',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategoryPostListAsync.pending, (state) => {
                return {
                    ...state,
                    loading: true
                }
            })
            .addCase(appendCategoryPostListAsync.fulfilled, (state, action) => {
                const { page, list, has_more } = action.payload
                return {
                    page,
                    loading: false,
                    list: [...state.list, ...list],
                    has_more
                }
            })
            .addCase(getCategoryPostListAsync.fulfilled, (state, action) => {
                return {
                    ...action.payload,
                    loading: false,
                }
            })
    }
})

export default postListSlice.reducer