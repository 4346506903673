import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getProfile } from '../../services'

export const updateProfileAsync = createAsyncThunk(
    'profile/getProfile',
    async () => {
        const res = await getProfile()

        return res.data;
    }
)

export type Profile = {
    id?: number;
    nickname: string;
    invite_code: string;
    score: {
        ticket: number;
        invite: number;
    }
}

const initialState: Profile = {
    nickname: 'Unknown',
    invite_code: '******',
    score: {
        ticket: 0,
        invite: 0,
    }
}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateProfileAsync.fulfilled, (state, action) => {
                return {
                    ...state,
                    ...action.payload
                }
            })
    }
})

export default profileSlice.reducer