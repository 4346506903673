import { Outlet, Link, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons'
import './layout.css'
import classNames from "classnames"

function AppLayout(props: any) {
    const location = useLocation()

    return <div className="app-container">
        <div><Outlet /></div>
        <div className="app-footer">
            <div className="app-tab-bar">
                <Link className={classNames('item', {active: location.pathname == '/'})} to="/">
                    <div><FontAwesomeIcon icon={faHome} size="lg" /></div>
                    <div className="name">首页</div>
                </Link>
                <Link className={classNames('item', {active: location.pathname == '/profile'})} to="/profile">
                    <div><FontAwesomeIcon icon={faUser} size="lg" /></div>
                    <div className="name">我的</div>
                </Link>
            </div>
        </div>
    </div>
}

export default AppLayout