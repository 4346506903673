import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faKey, faUser, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import { login } from "../services";
// @ts-ignore
import Modal from 'react-modal'

Modal.setAppElement('#root');

function Login() {
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm()
    const [modalState, setModalState] = useState(false)
    const [submitState, setSubmitState] = useState(0)

    let stateDesc;

    switch (submitState) {
        case 1:
            stateDesc = <div><FontAwesomeIcon icon={faSpinner} spin /> 登录中，请稍后</div>
            break;
        case 2:
            stateDesc = <div>登录失败，请稍后重试</div>
            break;
        default:
            stateDesc = <div></div>
    }

    const loginSubmit = async ({username, password}: any) => {
        setSubmitState(1)
        setModalState(true)

        try {
            const res = await login(username, password)

            if (res.data.token) {
                localStorage.setItem('_auth_token', res.data.token);

                navigate("/", { replace: true })
            }
        } catch (error) {
            setSubmitState(2)
            setTimeout(() => setModalState(false), 2000)
            console.error(error)
        }
    }

    useEffect(() => {
        document.title = '登录 - 污吧';
    }, [])

    return <div>
        <form className="form" onSubmit={handleSubmit(loginSubmit)}>
            <div className='form-item'>
                <label htmlFor="username">用户名</label>
                <div className="form-input">
                    <i><FontAwesomeIcon icon={faUser} /></i>
                    <input id="username" placeholder="用户名" {...register("username", { required: true })} autoComplete="username" />
                </div>
            </div>
            <div className='form-item'>
                <label htmlFor="password">密码</label>
                <div className="form-input">
                    <i><FontAwesomeIcon icon={faKey} /></i>
                    <input id="password" placeholder="密码" type="password" {...register("password")} autoComplete="current-password" />
                </div>
            </div>
            <div className="form-submit">
                <button type="submit" className="button primary">登录</button>
                <span className="text-gray-200 flex-none text-center mx-2 leading-10">- or -</span>
                <Link className="button default" to="/login/register">注册</Link>
            </div>
        </form>
        <Modal
            className="modal"
            isOpen={modalState}
        >
            {stateDesc}
        </Modal>
    </div>
}

export default Login