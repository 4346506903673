import axios from "../request";

export async function getCategoryList() {
    return await axios.get('/api/category')
}

export async function getCategoryPostList(category: string | number, page?: number) {
    if (!page) {
        page = 1
    }

    return await axios.get(`/api/category/${category}/post?page=${page}`)
}

export async function getPost(postId: string | number) {
    return await axios.get(`/api/s/post/${postId}`)
}

export async function getProfile() {
    return await axios.get('/api/s/profile')
}

export async function getInvites() {
    return await axios.get('/api/s/invite')
}

export async function login(username: string, password: string) {
    return await axios.post('/api/auth/login', {
        username, password
    })
}

export async function register(username: string, password: string, inviteCode?: string) {
    return await axios.post('/api/auth/register', {
        username, password, invite_code: inviteCode ? inviteCode : null
    })
}