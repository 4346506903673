// @ts-ignore
import Artplayer from "artplayer/examples/react/Artplayer";
import Hls from 'hls.js';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { getPost } from "../services";
// @ts-ignore
import Modal from 'react-modal'
import './styles/post.css'

let hls: Hls;

function Post() {
    const { postId } = useParams();
    const [post, setPost] = useState({ title: '', keywords: '', source: null })
    const [modalState, setModalState] = useState(false)
    const [loadState, setLoadState] = useState(0)
    const [loading, setLoading] = useState(true)
    const [playerHandle, setPlayerHandle] = useState<any>(null)

    const getPostAsync = async (id: number | string) => {
        try {
            const res = await getPost(id)
            const { title, keywords, content } = res.data;
            const { source } = JSON.parse(content) // 格式化内容

            document.title = `${res.data.title} - 观影 - 污吧`
            
            setPost({ title, keywords, source })
            setLoading(false)
        } catch (error: any) {
            if (error.response) {
                console.log(error.response)
                if (error.response.data.code === 12) {
                    setLoadState(2)
                    setModalState(true)
                    return
                }
            }

            setLoadState(1)
            setModalState(true)

            console.error(error)
        }
    }

    useEffect(() => {
        if (postId) {
            getPostAsync(postId)
        }

        return () => {
            hls.stopLoad()
            hls.detachMedia()
            hls.destroy()
            
            if (playerHandle && playerHandle.destroy) {
                playerHandle.destroy()
            }

            console.log('destroy player.')
        }
    }, [])

    let view;
    let modalView;

    if (loading) {
        view = <div className="loading">
            <FontAwesomeIcon icon={faSpinner} spin />
            内容正在加载中
        </div>
    } else {
        view = <Artplayer
            className={'w-full h-60'}
            option={{
                url: post.source,
                autoplay: false,
                customType: {
                    m3u8: function (video: any, url: string) {
                        console.log('source:', url);

                        hls = new Hls()
                        hls.loadSource(url);
                        hls.attachMedia(video);

                        // iOS 兼容处理
                        if (!video.src) {
                            video.src = url
                        }
                    },
                },
            }}
            getInstance={(art: any) => setPlayerHandle(art)}
        />
    }

    switch (loadState) {
        case 1:
            modalView = <p>加载视频失败，请稍后重试</p>
            break;
        case 2:
            modalView = <div className="p-2">
                <div className="mb-4">
                    <p>您的观影券不足，请点击下方按钮</p>
                    <p>通过邀请更多好友获得</p>
                </div>
                <div><Link className="jump-to-invite" to="/profile/invite">邀请好友</Link></div>
            </div>
    }

    return <div>
        <div className="content">
            <div className='source-player'>{view}</div>
            <div className="title">{post.title}</div>
        </div>
        
        <Modal
            isOpen={modalState}
            className="modal"
        >
            <div>{modalView}</div>
        </Modal>
    </div>
}

export default Post