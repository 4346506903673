import '../styles/profile.css'
import { useAppDispatch, useAppSelector } from "../../app/hooks"
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
// @ts-ignore
import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import { updateProfileAsync } from '../../app/profile/profileSlice';
import { getInvites } from '../../services';
import moment from 'moment'

Modal.setAppElement('#root');

function UserInviteCard(props: { inviteCode: string }) {
    const [modalState, setModalState] = useState(false)
    const { protocol, host } = window.location;

    const inviteLink = `${protocol}//${host}?s=${props.inviteCode}`

    return <>
        <CopyToClipboard
            text={inviteLink}
            onCopy={() => {
                console.log('copied invite link:', inviteLink)
                setModalState(true)
                setTimeout(() => setModalState(false), 1500)
            }}
        >
            <div className="invite-card">
                <div className="title">我的邀请码</div>
                <div className="code">{props.inviteCode}</div>
                <div className='desc'>点击复制邀请链接</div>
            </div>
        </CopyToClipboard>
        <Modal isOpen={modalState} className="modal">
            <div>邀请链接复制成功</div>
        </Modal>
    </>
}

function UserInvite() {
    const [inviteList, setInviteList] = useState([])
    const profile = useAppSelector(state => state.profile)
    const dispatch = useAppDispatch()

    const getInvitesAsync = async () => {
        const res = await getInvites()

        setInviteList(res.data)
    }

    useEffect(() => {
        document.title = '我的邀请 - 污吧'

        // 判断是否为无效邀请码
        if (profile.invite_code === '******') {
            dispatch(updateProfileAsync())
        }

        getInvitesAsync()
    }, [])

    return <div className="p-4">
        <UserInviteCard inviteCode={profile.invite_code} />
        <div className='invite-list'>
            <div className='title'>我的邀请记录</div>
            <div className='desc'>最多显示 20 条</div>
            <div className='list'>
                {inviteList.map((item: any) => <div key={item.id} className='item'>
                    <span>{item.username}</span>
                    <span className='date'>{moment(item.created_at).format('YYYY-MM-DD')}</span>
                </div>)}
            </div>
        </div>
    </div>
}

export default UserInvite